<template>
  <v-app>
    <div v-if="this.signedIn">

      <div class="home">
        <div class="homeContent">
          <h1>{{ this.greeting }}, {{ this.username }}</h1>
          <p><Emoji name='fire'/> {{ this.dailyStreak }} </p>
          <router-link v-if="this.userData.lessonsCompleted[0] != 0" to="/practice"><v-btn elevation='1' large class="mr-2" color="#90be6d">Practice Everything I've Learned</v-btn></router-link>
          <div v-if="$vuetify.breakpoint.xs" style="height:4px;"></div>
          <router-link to="/reference"><v-btn elevation='1' large color="white">Reference Grammar Tables</v-btn></router-link>
        </div>
      </div>


      <div class="greetingContainerMobile">
        <h1>{{ this.greeting }},<br> {{ this.username }}</h1>
        <p>&#128293; &nbsp;{{ this.dailyStreak }} </p>
        <router-link v-if="this.userData.lessonsCompleted[0] != 0" to="/practice"><v-btn elevation='1' class="mr-2" color="#90be6d">Practice Everything I've Learned</v-btn></router-link>
        <div v-if="$vuetify.breakpoint.xs" style="height:4px;"></div>
        <router-link to="/reference"><v-btn elevation='1' color="white">Reference Grammar Tables</v-btn></router-link>
      </div>

      <div class="progressInfo">
        <div class="progressBox">

            <h2>My Freshness

            <v-tooltip bottom opacity="1">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="pb-1"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>This represents how likely it is that you<br> can answer a question in a given case</span>
            </v-tooltip></h2>

          <Chart :freshnessValues='this.freshnessValues' :key="componentKey" />
        </div>


        <div class="progressBox">
          <h2>Weekly Streak</h2>
          <p>Consistency is the only way to improve!</p>
          <div class="weekStreak">
            <div class=""> Mon </div>
            <div class=""> Tue </div>
            <div class=""> Wed </div>
            <div class=""> Thu </div>
            <div class=""> Fri </div>
            <div class=""> Sat </div>
            <div class=""> Sun </div>
          </div>
          <div class="weekStreak">
            <div v-for="day in weeklyStreak" :key="day.id">
              <p v-if="day.value == 1" style="filter:none;"> <Emoji name='fire'/> </p>
              <p v-if="day.value != 1" style="filter:grayscale(100%);"> <Emoji name='fire'/> </p>
            </div>
          </div>
        </div>

        <div v-if="!$vuetify.breakpoint.xs" class="mt-8 progressBox">
          <router-link to="/settings"><v-btn class="ma-2" color="#dedede">
            <v-icon dark left> mdi-cog </v-icon>
            User Settings
          </v-btn></router-link>
        </div>
      </div>

      <div class="content">

        <div class="d-flex">
          <h2>Nominative</h2>
          <v-spacer></v-spacer>
        </div>

        <v-card
          :color="lesson.colour"
          dark
          max-width="600"
          elevation='1'
          class="mb-3 mt-2"
          v-for="lesson in lessonsNom"
          v-bind:key="lesson.id"
          v-bind:title="lesson.title"
          v-bind:freshness="lesson.freshness"
          @click="goToLesson(lesson.id)"
          :disabled="lessonAvailable(lesson.id)"
          :class="disabledColour(lesson.id)"
        >

          <div :class="ifFirstLesson(lesson.id)">

            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h6" >{{ lesson.title }}</v-card-title>
                <v-card-subtitle></v-card-subtitle>
              </div>


              <v-avatar class="ma-2" tile>
                <v-progress-circular
                :value="setNominativeFreshness(lesson.freshnessGender)"
                color="white"
                ></v-progress-circular>
              </v-avatar>
            </div>

          </div>

        </v-card>


        <br>


        <div class="d-flex">
          <h2>Accusative</h2>
          <v-spacer></v-spacer><v-btn
            color="#ffbe0b"
            elevation="1"
            dark
            :class="disabledColour('allAcc')"
            :disabled="lessonAvailable('allAcc')"
            @click="goToLesson('allAcc')"
          >Practice All</v-btn>
        </div>

        <v-card
          :color="lesson.colour"
          dark
          max-width="600"
          elevation='1'
          class="mb-3 mt-2"
          :class="disabledColour(lesson.id)"
          v-for="lesson in lessonsAcc"
          v-bind:key="lesson.id"
          v-bind:title="lesson.title"
          v-bind:freshness="lesson.freshness"
          @click="goToLesson(lesson.id)"
          :disabled="lessonAvailable(lesson.id)"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                class="text-h6"
              >{{ lesson.title }}</v-card-title>
              <v-card-subtitle></v-card-subtitle>
            </div>

            <v-avatar
              class="ma-2"
              tile
            >
              <v-progress-circular
                :value="freshnessValuesGenders[1][lesson.freshnessGender]"
                color="white"
              ></v-progress-circular>
            </v-avatar>
          </div>
        </v-card>


        <br>


        <div class="d-flex">
          <h2>Prepositional</h2>
          <v-spacer></v-spacer><v-btn
            color="#00b4d8"
            elevation="1"
            dark
            :class="disabledColour('allPrep')"
            :disabled="lessonAvailable('allPrep')"
            @click="goToLesson('allPrep')"
          >Practice All</v-btn>
        </div>

        <v-card
          :color="lesson.colour"
          dark
          max-width="600"
          elevation='1'
          class="mb-3 mt-2"
          :class="disabledColour(lesson.id)"
          v-for="lesson in lessonsPrep"
          v-bind:key="lesson.id"
          v-bind:title="lesson.title"
          @click="goToLesson(lesson.id)"
          :disabled="lessonAvailable(lesson.id)"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                class="text-h6"
              >{{ lesson.title }}</v-card-title>
              <v-card-subtitle></v-card-subtitle>
            </div>

            <v-avatar
              class="ma-2"
              tile
            >
              <v-progress-circular
                :value="freshnessValuesGenders[2][lesson.freshnessGender]"
                color="white"
              ></v-progress-circular>
            </v-avatar>
          </div>
        </v-card>


        <br>


        <div class="d-flex">
          <h2>Genitive</h2>
          <v-spacer></v-spacer><v-btn
            color="#e73265"
            elevation="1"
            dark
            :class="disabledColour('allGen')"
            :disabled="lessonAvailable('allGen')"
            @click="goToLesson('allGen')"
          >Practice All</v-btn>
        </div>

        <v-card
          :color="lesson.colour"
          dark
          max-width="600"
          elevation='1'
          class="mb-3 mt-2"
          :class="disabledColour(lesson.id)"
          v-for="lesson in lessonsGen"
          v-bind:key="lesson.id"
          v-bind:title="lesson.title"
          @click="goToLesson(lesson.id)"
          :disabled="lessonAvailable(lesson.id)"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                class="text-h6"
              >{{ lesson.title }}</v-card-title>
              <v-card-subtitle></v-card-subtitle>
            </div>

            <v-avatar
              class="ma-2"
              tile
            >
              <v-progress-circular
                :value="freshnessValuesGenders[3][lesson.freshnessGender]"
                color="white"
              ></v-progress-circular>
            </v-avatar>
          </div>
        </v-card>


        <br>


        <div class="d-flex">
          <h2>Dative</h2>
          <v-spacer></v-spacer><v-btn
            color="#90be6d"
            elevation="1"
            dark
            :class="disabledColour('allDat')"
            :disabled="lessonAvailable('allDat')"
            @click="goToLesson('allDat')"
          >Practice All</v-btn>
        </div>

        <v-card
          :color="lesson.colour"
          dark
          max-width="600"
          elevation='1'
          class="mb-3 mt-2"
          :class="disabledColour(lesson.id)"
          v-for="lesson in lessonsDat"
          v-bind:key="lesson.id"
          v-bind:title="lesson.title"
          @click="goToLesson(lesson.id)"
          :disabled="lessonAvailable(lesson.id)"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                class="text-h6"
              >{{ lesson.title }}</v-card-title>
              <v-card-subtitle></v-card-subtitle>
            </div>

            <v-avatar
              class="ma-2"
              tile
            >
            <v-progress-circular
              :value="freshnessValuesGenders[4][lesson.freshnessGender]"
              color="white"
            ></v-progress-circular>
            </v-avatar>
          </div>
        </v-card>


        <br>


        <div class="d-flex">
          <h2>Instrumental</h2>
          <v-spacer></v-spacer><v-btn
            color="#ada7c9"
            elevation="1"
            dark
            :class="disabledColour('allInst')"
            :disabled="lessonAvailable('allInst')"
            @click="goToLesson('allInst')"
          >Practice All</v-btn>
        </div>



          <v-card
            :color="lesson.colour"
            dark
            max-width="600"
            elevation='1'
            class="mb-3 mt-2"
            :class="disabledColour(lesson.id)"
            v-for="lesson in lessonsInst"
            v-bind:key="lesson.id"
            v-bind:title="lesson.title"
            @click="goToLesson(lesson.id)"
            :disabled="lessonAvailable(lesson.id)"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h6"
                >{{ lesson.title }}</v-card-title>
                <v-card-subtitle></v-card-subtitle>
              </div>

              <v-avatar
                class="ma-2"
                tile
              >
              <v-progress-circular
                :value="freshnessValuesGenders[5][lesson.freshnessGender]"
                color="white"
              ></v-progress-circular>
              </v-avatar>
            </div>
          </v-card>




        <br>







      </div>

      <div v-if="$vuetify.breakpoint.xs" class="mt-2 mb-6 progressBox">

        <v-btn v-if="signedIn" color="#dedede" style="color:#000" @click="googleSignOut">Sign Out</v-btn>

        <router-link to="/settings"><v-btn class="ma-2" color="#dedede">
          <v-icon dark left> mdi-cog </v-icon>
          User Settings
        </v-btn></router-link>
      </div>


      <div class="basilMobile"></div>

    </div>
  </v-app>
</template>

<script>
import {db} from '../firebase/db';
import firebase from 'firebase';

import Chart from '@/components/Chart.vue'

import Emoji from '@/components/Emoji.vue'


export default {
  title: "Dashboard",
  props: [],
  components: {
    Chart,
    Emoji
  },
  data() {
    return {
      greeting: "Привет",
      signedIn: false,
      username: "",
      userData: [],
      componentKey: 0,
      freshnessValues: [0, 0, 0, 0, 0, 0],
      freshnessScores: [30, 40, 50, 3, 20, 80],
      freshnessValuesGenders: [0, 0, 0, 0, 0, 0],
      identifyGenderFreshnessScore: 0,
      weeklyStreak: [
          { id: 'mon', value: 0 },
          { id: 'tue', value: 0 },
          { id: 'wed', value: 0 },
          { id: 'thu', value: 0 },
          { id: 'fri', value: 0 },
          { id: 'sat', value: 0 },
          { id: 'sun', value: 0 }
        ],
      dailyStreak: "",
      lessonsNom: [
            { id: "nom"+1, title: 'Identify grammatical gender', freshnessGender: 0, colour: "#6c757d" },
            { id: "nom"+2, title: 'Nominative nouns', freshnessGender: 1, colour: "#495057" },
            { id: "nom"+3, title: 'Nominative adjectives', freshnessGender: 2, colour: "#343a40" }
          ],
      lessonsAcc: [
            { id: "acc"+1, title: 'Masculine nouns', freshnessGender: 0, colour: "#ffba08" },
            { id: "acc"+2, title: 'Masculine adjectives', freshnessGender: 1, colour: "#ffba08" },
            { id: "acc"+3, title: 'Feminine nouns', freshnessGender: 2, colour: "#faa307" },
            { id: "acc"+4, title: 'Feminine adjectives', freshnessGender: 3, colour: "#faa307" },
            { id: "acc"+5, title: 'Neuter nouns', freshnessGender: 4, colour: "#f48c06" },
            { id: "acc"+6, title: 'Neuter adjectives', freshnessGender: 5, colour: "#f48c06" },
            { id: "acc"+7, title: 'Plural adjectives', freshnessGender: 6, colour: "#e85d04" }
          ],
      lessonsPrep: [
            { id: "prep"+1, title: 'Masculine nouns', freshnessGender: 0, colour: "#00b4d8" },
            { id: "prep"+2, title: 'Masculine adjectives', freshnessGender: 1, colour: "#00b4d8" },
            { id: "prep"+3, title: 'Feminine nouns', freshnessGender: 2, colour: "#0096c7" },
            { id: "prep"+4, title: 'Feminine adjectives', freshnessGender: 3, colour: "#0096c7" },
            { id: "prep"+5, title: 'Neuter nouns', freshnessGender: 4, colour: "#0077b6" },
            { id: "prep"+6, title: 'Neuter adjectives', freshnessGender: 5, colour: "#0077b6" },
            { id: "prep"+7, title: 'Plural adjectives', freshnessGender: 6, colour: "#023e8a" },
          ],
      lessonsGen: [
            { id: "gen"+1, title: 'Masculine nouns', freshnessGender: 0, colour: "#e73265" },
            { id: "gen"+2, title: 'Masculine adjectives', freshnessGender: 1, colour: "#e73265" },
            { id: "gen"+3, title: 'Feminine nouns', freshnessGender: 2, colour: "#c9184a" },
            { id: "gen"+4, title: 'Feminine adjectives', freshnessGender: 3, colour: "#c9184a" },
            { id: "gen"+5, title: 'Neuter nouns', freshnessGender: 4, colour: "#a4133c" },
            { id: "gen"+6, title: 'Neuter adjectives', freshnessGender: 5, colour: "#a4133c" },
            { id: "gen"+7, title: 'Plural adjectives', freshnessGender: 6, colour: "#800f2f" },
          ],
      lessonsDat: [
            { id: "dat"+1, title: 'Masculine nouns', freshnessGender: 0, colour: "#90be6d" },
            { id: "dat"+2, title: 'Masculine adjectives', freshnessGender: 1, colour: "#90be6d" },
            { id: "dat"+3, title: 'Feminine nouns', freshnessGender: 2, colour: "#74a94c" },
            { id: "dat"+4, title: 'Feminine adjectives', freshnessGender: 3, colour: "#74a94c" },
            { id: "dat"+5, title: 'Neuter nouns', freshnessGender: 4, colour: "#5e893e" },
            { id: "dat"+6, title: 'Neuter adjectives', freshnessGender: 5, colour: "#5e893e" },
            { id: "dat"+7, title: 'Plural adjectives', freshnessGender: 6, colour: "#4b6d31" },
          ],
      lessonsInst: [
            { id: "inst"+1, title: 'Masculine nouns', freshnessGender: 0, colour: "#ada7c9" },
            { id: "inst"+2, title: 'Masculine adjectives', freshnessGender: 1, colour: "#ada7c9" },
            { id: "inst"+3, title: 'Feminine nouns', freshnessGender: 2, colour: "#9890bb" },
            { id: "inst"+4, title: 'Feminine adjectives', freshnessGender: 3, colour: "#9890bb" },
            { id: "inst"+5, title: 'Neuter nouns', freshnessGender: 4, colour: "#7e74aa" },
            { id: "inst"+6, title: 'Neuter adjectives', freshnessGender: 5, colour: "#7e74aa" },
            { id: "inst"+7, title: 'Plural adjectives', freshnessGender: 6, colour: "#665b95" },
           ],
      foundNextLesson: false,
      nextLesson: "",
    }
  },
  methods: {

    //This is required for mobile version
    googleSignOut(){
      firebase.auth().signOut().then(() => {
        // alert("Signed Out!");
        this.$router.push('/');
        console.log("Signed out")
      }).catch((error) => {
        console.log(error);
      });

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          this.uid = user.uid;
          this.displayName = user.displayName;
          this.email = user.email;
          this.photoURL = user.photoURL;
          this.emailVerified = user.emailVerified;
          this.signedIn = true;
        } else {
          // User is signed out
          // ...
          this.signedIn = false;
        }
      });
    },

    ifFirstLesson(lessonID) {

      // console.log(this.userData.lessonsCompleted[0]);
      // console.log(this.userData.lessonsCompleted);

      if (lessonID == 'nom1' && this.userData.lessonsCompleted[0] == 0) {
        return 'boxShadowAnimation'
      }

    },

    lessonAvailable(lessonID) {

      if (lessonID == 'nom1') {
        return false;
      } else if (lessonID == 'nom2') {

        if (this.userData.lessonsCompleted[0] == -1 || this.userData.lessonsCompleted[0] == 3) {
          return false;
        }

      } else if (lessonID == 'nom3') {
        if (this.userData.lessonsCompleted[0] > 0) {
          return false;
        }
      }

      var caseAbbreviations = ['nom', 'acc', 'prep', 'gen', 'dat', 'inst'];
      var lessonCase = lessonID.substring(0, lessonID.length - 1)
      var lessonNumber = lessonID.substring(lessonID.length - 1, lessonID.length)
      var index = caseAbbreviations.indexOf(lessonCase);

      var practiceAll = ['allNom', 'allAcc', 'allPrep', 'allGen', 'allDat', 'allInst']
      if (practiceAll.includes(lessonID)) {
        if ((this.userData.lessonsCompleted[practiceAll.indexOf(lessonID)] + this.userData.lessonsCompletedAdjectives[practiceAll.indexOf(lessonID)]) == 7) {
          return false;
        } else {
          return true;
        }
      }

      if ((this.userData.lessonsCompleted[index] + this.userData.lessonsCompletedAdjectives[index]) >= lessonNumber) {
        return false;
      } else {

        var completedInCase = (this.userData.lessonsCompleted[index] + this.userData.lessonsCompletedAdjectives[index]);

        if (lessonNumber == (completedInCase + 1) && completedInCase != 0) {
          return false;
        }

        if ((completedInCase) == 0) {
          if ((this.userData.lessonsCompleted[index - 1] + this.userData.lessonsCompletedAdjectives[index - 1]) == 7) {
            if (lessonNumber == 1) {
              return false;
            } else {
              return true;
            }
          }
        }

        return true;
      }

    },

    disabledColour(lessonID) {

      if (lessonID == 'nom1') {
        return;
      } else if (lessonID == 'nom2') {

        if (this.userData.lessonsCompleted[0] == -1 || this.userData.lessonsCompleted[0] == 3) {
          return;
        }

      } else if (lessonID == 'nom3') {
        if (this.userData.lessonsCompleted[0] > 0) {
          return;
        }
      }

      var caseAbbreviations = ['nom', 'acc', 'prep', 'gen', 'dat', 'inst'];
      var lessonCase = lessonID.substring(0, lessonID.length - 1)
      var lessonNumber = lessonID.substring(lessonID.length - 1, lessonID.length)
      var index = caseAbbreviations.indexOf(lessonCase);

      var practiceAll = ['allNom', 'allAcc', 'allPrep', 'allGen', 'allDat', 'allInst']
      if (practiceAll.includes(lessonID)) {
        if (this.userData.lessonsCompleted[practiceAll.indexOf(lessonID)] > 0) {
          return;
        } else {
          return 'disabledColour';
        }
      }

      if ((this.userData.lessonsCompleted[index] + this.userData.lessonsCompletedAdjectives[index]) >= lessonNumber) {
        return;
      } else {

        var completedInCase = (this.userData.lessonsCompleted[index] + this.userData.lessonsCompletedAdjectives[index]);

        if (lessonNumber == (completedInCase + 1) && completedInCase != 0) {
          return;
        }

        if ((completedInCase) == 0) {
          if ((this.userData.lessonsCompleted[index - 1] + this.userData.lessonsCompletedAdjectives[index - 1]) == 7) {
            if (lessonNumber == 1) {
              return;
            } else {
              return 'disabledColour';
            }
          }
        }

        return 'disabledColour';
      }

    },

    goToLesson(lessonid){
      console.log(lessonid);
      if (lessonid.substring(0, 3) == "all") {
        this.$router.push({ name: 'Practice', params: {lesson: lessonid }})
      } else {
        this.$router.push({ name: 'Lesson', params: {lesson: lessonid }})
      }
    },

    setGreeting(){
      var dateNow = new Date();
      var currentHour = dateNow.getHours();

      switch(true) {
        case currentHour > 3 && currentHour < 12:
          this.greeting = "Доброе утро";
          break;
        case currentHour > 11 && currentHour < 18:
          this.greeting = "Добрый день";
          break;
        case (currentHour > 17 && currentHour <= 24) || currentHour < 4:
          this.greeting = "Добрый вечер";
          break;
        default:

      }
    },

    setNominativeFreshness(lessonID){

      lessonID++;

      if (lessonID == '1') {
        return this.identifyGenderFreshnessScore;
      } else if (lessonID == '2') {
        //return average noun
        //0, 2, 4
        return (this.freshnessValuesGenders[0][0] + this.freshnessValuesGenders[0][2] + this.freshnessValuesGenders[0][4]) / 3
      } else if (lessonID == '3') {
        //return average adjectives
        //1, 3, 5, 6
        return (this.freshnessValuesGenders[0][1] + this.freshnessValuesGenders[0][3] + this.freshnessValuesGenders[0][5] + this.freshnessValuesGenders[0][6]) / 4
      }

    },

    async updateFreshnessChart() {

      const today = new Date()
      today.setHours(0,0,0,0)

      this.userData = await db.collection('Users').doc(this.uid).get();
      this.userData = this.userData.data();

      var caseAbbreviations = ['nom', 'acc', 'prep', 'gen', 'dat', 'inst'];
      var typeAbbreviations = ['MascNoun', 'MascAdj', 'FemNoun', 'FemAdj', 'NeutNoun', 'NeutAdj', 'PluAdj'];

      for (var i = 0; i < caseAbbreviations.length; i++) {

        var freshnessScore = 0;
        var freshnessEachGender = [];
        var caseForgettingCurve = caseAbbreviations[i] + "ForgettingCurve";

        for (var l = 0; l < 7; l++) {
          if ((this.userData[caseForgettingCurve][(l*3)]) != -999) {
            var fetchedDate = Date.parse(this.userData[caseForgettingCurve][(l*3)+1])
            const diffTime = Math.abs(today - fetchedDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            var forgettingCurveScore = 100*(1 - (this.userData[caseForgettingCurve][(l*3)]))**diffDays

            var caseTypePastPerformance = caseAbbreviations[i] + typeAbbreviations[l] + "PastPerformance";

            var sumI = 0;
            for (var j = 1; j <= this.userData[caseTypePastPerformance].length; j++) {
              sumI += j;
            }

            var pastPerformanceScore = 0;
            for (var k = 0; k < this.userData[caseTypePastPerformance].length; k++) {
              pastPerformanceScore += ((k + 1) * this.userData[caseTypePastPerformance][k]) / sumI
            }
            pastPerformanceScore = pastPerformanceScore * 100

            freshnessScore += ((forgettingCurveScore + pastPerformanceScore) / 2)

            freshnessEachGender.push(((forgettingCurveScore + pastPerformanceScore) / 2))

          } else {
            freshnessEachGender.push(0)
          } // end of if not -999
        } // end of loop x 3

        freshnessScore = freshnessScore / 7;
        this.freshnessValues[i] = Math.floor(freshnessScore);
        this.freshnessValuesGenders.shift()
        this.freshnessValuesGenders.push(freshnessEachGender);
      } //end for each case










      var identifyGenderFreshness;


      if ((this.userData.identifyGenderForgettingCurve[0]) != -999) {
        fetchedDate = Date.parse(this.userData.identifyGenderForgettingCurve[1])
        var diffTime2 = Math.abs(today - fetchedDate);
        var diffDays2 = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));

        var forgettingCurveScore2 = 100*(1 - (this.userData.identifyGenderForgettingCurve[0]))**diffDays2

        //var caseTypePastPerformance = caseAbbreviations[i] + typeAbbreviations[l] + "PastPerformance";

        var sumI2 = 0;
        for (var h = 1; h <= this.userData.identifyGenderPastPerformance.length; h++) {
          sumI2 += h;
        }

        var pastPerformanceScore2 = 0;
        for (var n = 0; n < this.userData.identifyGenderPastPerformance.length; n++) {
          pastPerformanceScore2 += ((n + 1) * this.userData.identifyGenderPastPerformance[n]) / sumI2
        }
        pastPerformanceScore2 = pastPerformanceScore2 * 100

        identifyGenderFreshness = ((forgettingCurveScore2 + pastPerformanceScore2) / 2)

        // identifyGenderFreshness = freshnessScore

        // freshnessEachGender.push(((forgettingCurveScore + pastPerformanceScore) / 2))

      } else {
        identifyGenderFreshness = 0
      }


      this.identifyGenderFreshnessScore = identifyGenderFreshness;







      this.freshnessValues[0] =
          (((this.freshnessValuesGenders[0][0] + this.freshnessValuesGenders[0][2] + this.freshnessValuesGenders[0][4]) / 3) +
          ((this.freshnessValuesGenders[0][1] + this.freshnessValuesGenders[0][3] + this.freshnessValuesGenders[0][5] + this.freshnessValuesGenders[0][6]) / 4) +
          identifyGenderFreshness) / 3;

      this.freshnessValues[0] = Math.floor(this.freshnessValues[0])

      // Forces Chart update -> for some reason it doesn't dynamically update without this...!
      this.componentKey += 1;

      this.setNominativeFreshness();

    },

    async updateWeeklyStreak() {

      this.userData = await db.collection('Users').doc(this.uid).get();
      this.userData = this.userData.data();

      var d = new Date(); // current time

      const today = new Date()
      const yesterday = new Date(today)

      var pastWeek = []

      pastWeek.push(today.toDateString())
      for (var i = 1; i < 7; i++) {
        yesterday.setDate(yesterday.getDate() - 1)
        pastWeek.unshift(yesterday.toDateString())
      }

      var currentDay = d.getDay();
      while (pastWeek[currentDay] != today.toDateString()) {
        var temp = pastWeek.shift();
        pastWeek.push(temp);
      }
      temp = pastWeek.shift();
      pastWeek.push(temp);

      temp = this.userData.weeklyStreak.shift();
      this.userData.weeklyStreak.push(temp);

      for (i = 0; i < 7; i++) {
        if (pastWeek[i] == this.userData.weeklyStreak[i]) {
          this.weeklyStreak[i].value = 1
        } else {
          this.weeklyStreak[i].value = 0
        }
      }

    },

    async updateDailyStreak() {

      var today = new Date()
      today.setHours(0,0,0,0)
      today = today.toDateString()

      var yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday = yesterday.toDateString()

      //Realise: have 3+ methods all doing this .get: make this better!
      this.userData = await db.collection('Users').doc(this.uid).get();
      this.userData = this.userData.data();

      if ((this.userData.lastPractice == null) || ((this.userData.lastPractice != today) && (this.userData.lastPractice != yesterday))) {
        this.dailyStreak = "Start your Russian cases journey today!"
      } else if (this.userData.totalStreak == 1) {
        this.dailyStreak = this.userData.totalStreak + " day."
      } else if (this.userData.totalStreak > 1) {
        this.dailyStreak = this.userData.totalStreak + " days."
      }

      if (this.userData.lastPractice == today) {
        this.dailyStreak += " Great work practicing today."
      } else if (this.userData.lastPractice == yesterday) {
        this.dailyStreak += " You practiced yesterday, keep it going today."
      }

      this.signedIn = true;

    }




  },
  async beforeMount() {

    window.scrollTo(0,0);

    this.userData.lessonsCompleted = [0, 0, 0, 0, 0, 0]
    this.userData.lessonsCompletedAdjectives = [0, 0, 0, 0, 0, 0]

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;
        this.uid = user.uid;
        this.username = user.displayName;
        this.signedIn = true

        this.setGreeting();
        this.updateFreshnessChart();
        this.updateWeeklyStreak();
        this.updateDailyStreak();

      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });



  }
}

</script>


<style scoped>
  h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  .router-link-exact-active a {
    color: #fff;
  }

  .home {
    height: 260px;
    text-align: left;
    background-color: #383838;
    background-image: url(../assets/basil.png);
    background-size: 350px;
    background-repeat: no-repeat;
    background-position: calc(50vw + 175px) 0px;
    position: relative;
    align-content: center;
  }

  .homeContent {
    color: #fff;
    max-width: 1000px;
    font-size: 1.2em;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: calc(50vw - 500px)
  }

  .content {
    padding: 20px 0 0 0;
    text-align: left;
    max-width: 600px;
    margin-left: calc(50vw - 500px);
  }

  .progressInfo {
    text-align: right;
    max-width: 400px;
    width:380px;
    padding: 20px;
    float:right;
    margin-right: calc(50vw - 500px);
  }

  .progressBox {
    text-align: center;
  }

  .progressBox p {
    font-size: 0.8em;
  }

  .weekStreak {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
    grid-auto-rows: minmax(38px, auto);
  }

  .disabledColour {
    opacity: 0.3;
  }

  .caseIcon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: auto;
    color: #fff;
    border: 3px solid rgba(0,0,0,0.75);
    background-size: 200%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }

  .basilMobile {
    display: none;
  }

  .mobile-break {
    display: none;
  }

  .greetingContainerMobile {
    display: none;
  }

  .v-tooltip__content.menuable__content__active {
    opacity: 1!important;
    background: var(--v-tooltip-bg, #383838) !important;
  }

  .boxShadowAnimation {
    box-shadow: 0px 0px 6px 3px #ffb703;
    animation: boxShadowAnimation 2s linear infinite;
  }

  @keyframes boxShadowAnimation {
    0% {
      box-shadow: 0px 0px 6px 3px #ffb703;
    }

    50% {
      box-shadow: 0px 0px 18px 12px #ffb703;
    }

    100% {
      box-shadow: 0px 0px 6px 3px #ffb703;
    }
  }





</style>












<style scoped>
  @media screen and (max-width: 1024px) {

    h1 {
      font-size: 2em;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.8em;
    }

    .homeContent {
      width: 100vw;
      margin: 0;
      padding: 0 10px 20px 10px;
      left: 0;
      max-width: none;
      height: auto;
    }

    .home {
      margin: 0;
      padding: 0 0 10px 0;
      background-image: none;
      background-size: 0;
      display: none;
    }

    .greetingContainerMobile {
      display: block;
      text-align: center;
      background-color: #383838;
      color: #fff;
      padding: 10px;
    }

    .content {
      width: 100vw;
      margin: 0;
      padding: 0 10px;
      max-width: none;
    }

    .progressInfo {
      float:none;
      padding: 0;
      margin: 10px 0 0 0;
      width: 100vw;
      max-width: none;
      text-align: center;
    }

    .progressBox {
      width: 100vw;
      margin: 0;
      padding: 0;
      max-width: none;
    }

    .mobileBreak{
      height: 200px;
    }

    .basilMobile {
      display: block;
      height: 280px;
      background-image: url(../assets/basil.png);
      background-size: 300px;
      background-repeat: no-repeat;
      background-position: calc(50vw - 150px) 0px;
      position: relative;
    }


  }
</style>















<!--  -->
